'use client';
import { useClientTeasersOnPageAtom } from '@haaretz/s-atoms/clientTeasersOnPage';
import usePage from '@haaretz/s-atoms/page';
import useTags from '@haaretz/s-atoms/tags';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import useList, { useListActions } from '@haaretz/s-use-list';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import React from 'react';

import lazyListLoader from '../../server-actions/lazyListLoader';
import personalListLoader from '../../server-actions/personalListLoader';
import getArticleType from '../../utils/getArticleType';

import type { MandatoryListProps, WithClientListProps } from '@haaretz/s-data-structure-types';
import type { ListFragment } from '@haaretz/s-fragments/List';

export interface PersonalListProps
  extends WithClientListProps<MandatoryListProps>,
    Pick<ListFragment, 'renderedArticleIds'> {}

export default function PersonalListClient({
  children: placeholder,
  ...props
}: React.PropsWithChildren<PersonalListProps>) {
  const { inView, obeservedElement } = useList();
  const { setIsShowing } = useListActions();
  const [list, setList] = React.useState<React.ReactNode>(null);
  const isLoaded = useLoadEvent();
  const page = usePage();
  const [{ getExcludeString }, { addClientTeasers }] = useClientTeasersOnPageAtom();

  const isPersonalList = props.kind === 'personal';
  const [, startPersonalTransition] = React.useTransition();
  const [, startLazyTransition] = React.useTransition();

  const pageType = getArticleType(props.pageType);

  const prevContentIdRef = React.useRef<string | undefined>();

  const { pageContentId, articleSectionId } = page || {};

  const shouldRenderList =
    isPersonalList && isLoaded && inView && prevContentIdRef.current !== pageContentId;

  const tags = useTags();
  const tagIds = tags?.map(tag => tag.contentId);

  const exclude = getExcludeString(props.renderedArticleIds);

  const handleLoadLazyList = React.useCallback(
    function handleLoadMore() {
      startLazyTransition(async () => {
        try {
          const result = await lazyListLoader({
            listStyle: props.listStyle,
            postCacheParams: 'sa-list-lazy',
            variables: {
              mainContentId: pageContentId,
              id: props.contentId,
              exclude,
              page: 1,
            },
          });

          if (result?.html) {
            setList(result.html);
            addClientTeasers(result.itemIds);
          } else {
            setIsShowing(false);
            console.error(new Error('Lazy list failed to load'));
          }
        } catch (error) {
          /* istanbul ignore next */
          setIsShowing(false);
          /* istanbul ignore next */
          console.error(error);
        }
      });
    },
    [props.listStyle, props.contentId, pageContentId, exclude, addClientTeasers, setIsShowing]
  );

  const handleLoadPersonalList = React.useCallback(
    function handleLoadMore() {
      startPersonalTransition(async () => {
        try {
          const result = await personalListLoader({
            postCacheParams: 'sa-list-personal',
            listStyle: props.listStyle,
            variables: discardNullableValues({
              listId: props.contentId,
              pageType,
              exclude,
              ...(pageContentId && articleSectionId
                ? {
                    articleId: pageContentId,
                    sectionId: articleSectionId,
                    tagsIds: tagIds?.join(',') ?? '',
                  }
                : {
                    articleId: undefined,
                  }),
            }),
          });

          /* istanbul ignore next */
          if (result?.url) {
            console.table({
              title: 'PersonalList',
              style: props.listStyle,
              contentId: props.contentId,
              url: result.url,
              body: result.body,
              result: result.result,
              teaserIds: result.itemIds.toString(),
            });
          }

          if (result?.html) {
            setList(result.html);
            addClientTeasers(result.itemIds);
          } else if (props.listStyle === 'Spot') {
            setIsShowing(false);
          } else {
            handleLoadLazyList();
          }
        } catch (error) {
          /* istanbul ignore next */
          console.error(error);
          /* istanbul ignore next */
          handleLoadLazyList();
        }
      });
    },
    [
      addClientTeasers,
      articleSectionId,
      exclude,
      handleLoadLazyList,
      pageContentId,
      pageType,
      props.contentId,
      props.listStyle,
      setIsShowing,
      tagIds,
    ]
  );

  React.useEffect(() => {
    if (shouldRenderList) {
      prevContentIdRef.current = pageContentId;
      handleLoadPersonalList();
    }
  }, [handleLoadPersonalList, pageContentId, shouldRenderList]);

  return (
    list || (
      <>
        {obeservedElement}
        {placeholder}
      </>
    )
  );
}
