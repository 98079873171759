import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import getBiDataFromTeaser from '@haaretz/s-use-bi/getBiDataFromTeaser';
import s9 from 'style9';

import Gallery from './components/Gallery';
import MobileGalleryTeaser from './components/MobileGalleryTeaser';
import SlimTeaser from './components/SlimTeaser';

import type { ListProps } from '@haaretz/s-data-structure-types';
import type { GalleryFragment } from '@haaretz/s-fragments/Gallery';
import type { SlimListFragment } from '@haaretz/s-fragments/lists/SlimFragment.list';

const c = s9.create({
  listTitle: {
    display: 'none',
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'block',
          gridColumnEnd: 'span 12',
          paddingTop: space(2),
          paddingBottom: space(1),
          color: color('textLightAllModes'),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(1, { lines: 7 }),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(0),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-1),
        },
      })
    ),
  },
  divider: {
    display: 'none',
    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          display: 'block',
          backgroundColor: color('neutral100'),
          height: '1px',
          gridColumnStart: '1',
          gridColumnEnd: 'span 12',
          marginInlineStart: 'calc(-1 * var(--LayoutContainerGutter))',
          marginInlineEnd: 'calc(-1 * var(--LayoutContainerGutter))',
        },
      }),
      mq({
        from: 's',
        until: 'xl',
        value: { marginTop: space(2), marginBottom: space(3) },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          marginBottom: space(5),
        },
      }),
      mq({ from: 'xl', value: { display: 'none' } })
    ),
  },
});

type SlimListTeaser = SlimListFragment['items'][number];

export function isGallery(candidate: SlimListTeaser['media']): candidate is GalleryFragment {
  return candidate?.__typename === 'Gallery';
}

export default function SlimList(props: ListProps<SlimListFragment>) {
  const { items } = props;

  const gallery = items[0];
  const teasers = items.slice(1);

  const galleryBiData = getBiDataFromTeaser({ teaser: gallery, index: 0, props });

  return (
    <>
      <h3 className={s9(c.listTitle)}>{props.title}</h3>
      {isGallery(gallery.media) ? (
        <>
          <Gallery {...gallery} />
          <MobileGalleryTeaser biData={galleryBiData} teaser={gallery} />
        </>
      ) : null}

      <div className={s9(c.divider)} />

      {teasers.map((teaser, index) => {
        const biData = getBiDataFromTeaser({ teaser, index, props });
        return <SlimTeaser key={teaser.contentId} index={index} teaser={teaser} biData={biData} />;
      })}
    </>
  );
}
